// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outer-container {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    background-color: #242424;
    font-family: 'Roboto', sans-serif;
}

.container-shivom {
    width: 600px;
    height: 600px;
    flex: 1 1; 
    display: flex;
    flex-direction: column;
    justify-content: flex-end; 
    padding: 20px;
    overflow: hidden;
}

.textarea-shivom {
    width: 550px;
    height: 50px;
    padding: 12px;
    resize: none;
    background-color: transparent;
    color: white !important;
    border: 2px solid white;
    border-radius: 8px;
    overflow: hidden;
    margin-top: auto; 
}

.textarea-shivom::placeholder {
    color: white !important;
}
.output {
    width: 100%;
    height: 750px;
    padding: 12px;
    background-color: transparent;
    color: white !important;
    text-align: left;
    overflow-y: hidden;
    margin-bottom: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/webApiProjects/FinancialAssistant/finStyles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,SAAS;IACT,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,iCAAiC;AACrC;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,SAAO;IACP,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,6BAA6B;IAC7B,uBAAuB;IACvB,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;AAC3B;AACA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,6BAA6B;IAC7B,uBAAuB;IACvB,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".outer-container {\n    height: 100%;\n    margin: 0;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center; \n    background-color: #242424;\n    font-family: 'Roboto', sans-serif;\n}\n\n.container-shivom {\n    width: 600px;\n    height: 600px;\n    flex: 1; \n    display: flex;\n    flex-direction: column;\n    justify-content: flex-end; \n    padding: 20px;\n    overflow: hidden;\n}\n\n.textarea-shivom {\n    width: 550px;\n    height: 50px;\n    padding: 12px;\n    resize: none;\n    background-color: transparent;\n    color: white !important;\n    border: 2px solid white;\n    border-radius: 8px;\n    overflow: hidden;\n    margin-top: auto; \n}\n\n.textarea-shivom::placeholder {\n    color: white !important;\n}\n.output {\n    width: 100%;\n    height: 750px;\n    padding: 12px;\n    background-color: transparent;\n    color: white !important;\n    text-align: left;\n    overflow-y: hidden;\n    margin-bottom: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
