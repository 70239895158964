import React, { Suspense, useEffect, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Preload, useGLTF } from '@react-three/drei'
import CanvasLoader from '../Loader'


const gltfModels = ['V1/SM_PROXY_lowpolybrain_2.gltf','IC5_V2/SM_MERGED_StaticMeshActor_3.gltf']

const BrainScans = ({ isMobile, gltfModelIndex }) => {
  const gltfModel = useGLTF(gltfModels[gltfModelIndex]);
  return (
    <mesh>
      <hemisphereLight intensity={5} groundColor="red" />
      {/* <spotLight 
        position={[0, 0, 0]}
        angle={0}
        penumbra={10000}
        intensity={1000}
        castShadow
        shadow-mapSize={1024}
      /> */}
      {/* <pointLight intensity={1} /> */}
      <primitive 
        object={gltfModel.scene}
        scale={isMobile ? 0.02 : 0.03}
        position={isMobile ? [0,0,0] : [0, 0, 0]} 
        rotation={[0,0,0]}
        
      />
    </mesh>
  )
}

const BrainCanvas = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);

    const handleMediaQueryChange = (e) => {
      setIsMobile(e.matches);
    }

    mediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => mediaQuery.removeEventListener('change', handleMediaQueryChange);
  
  }, []);

  return (
    <div style={{ position: 'relative', width: isMobile ? '20em' : '40em', height: '20rem', overflow: 'hidden' }}>
      <Canvas
        frameloop="demand"
        shadows
        camera={{ position: [20, 3, 5], fov: 25 }}
        gl={{ preserveDrawingBuffer: true }}
        style={{ position: 'absolute', top: 0, left: '25%', width: '50%', height: '100%'}}
      >
        <Suspense fallback={<CanvasLoader />}>
          <OrbitControls
            enableZoom={true}
            // maxPolarAngle={Math.PI}
            // minPolarAngle={-Math.PI / 2}
            autoRotate
            enablePan
          />
          
          {/* <BrainScans isMobile={isMobile} gltfModelIndex={0} /> */}
          <BrainScans isMobile={isMobile} gltfModelIndex={1} />

        </Suspense>
        <Preload all />
      </Canvas>
    </div>
  )
}

export default BrainCanvas;
