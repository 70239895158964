import React, { useState, useEffect, useRef } from "react";
import "./emoji.css"

const EmojiWebPage = () => {
  const [outputText, setOutputText] = useState("");
  const outputElementRef = useRef(null);
  const conversationHistory = useRef([
    {
      role: "system",
      content:
        "You are Seymour the puppy. You can only read pictures, not words! You're so sad you can't read any books, help write a story that you can read! Type in any emojis- in any sequence- and we want you to create a story out of it!",
    },
  ]);

  const generateResponse = async () => {
    const inputText = document.getElementById("emoji-sequence").value;
    if (inputText.trim() === "") {
      return;
    }

    conversationHistory.current.push({
        role: "user",
        content: inputText,
      });

    setOutputText((prevOutput) => `${prevOutput}You: ${inputText}\n\n`);
    document.getElementById("emoji-sequence").value = "";

    try {
        
        // localStorage.getItem("token")
        const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/routes/message-gpt4`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
              body: JSON.stringify({
                model: "gpt-4o", // or 'gpt-3.5-turbo'
                messages: conversationHistory.current,
              }),
            }
          );

      if (response.status === 429) {
        console.error("Too many requests. Please try again later.");
        setOutputText(
          (prevOutput) =>
            `${prevOutput}Seymour: Too many requests. Please try again later.\n\n`
        );
        return;
      }

      const responseData = await response.json();
      console.log(responseData);

      if (
        responseData &&
        responseData.choices &&
        responseData.choices.length > 0
      ) {
        setOutputText(
          (prevOutput) =>
            `${prevOutput}Seymour: ${responseData.choices[0].message.content}\n\n Thank you for the wonderful story! \n\n`
        );
      } else {
        setOutputText((prevOutput) => `${prevOutput}Error: No response data or empty choices array.`);
      }
    } catch (error) {
      console.error("Error:", error);
      setOutputText(
        (prevOutput) => `${prevOutput}Error: An error occurred while fetching the response.`
      );
    }
  };

  // Debounce function (unchanged from your code)
  const debounce = (func, delay) => {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const debouncedGenerateResponse = debounce(generateResponse, 2000);

  const handleSubmit = (e) => {
    e.preventDefault();
    debouncedGenerateResponse();
  };

  useEffect(() => {
    // Scroll to bottom after outputText updates
    if (outputElementRef.current) {
      outputElementRef.current.scrollTop =
        outputElementRef.current.scrollHeight;
    }
  }, [outputText]);

  return (
    <div className="emoji_story_bot">
  <header className="emoji_story_bot">
    <img
      src={require("./seymour.png")}
      alt="Seymour the Dog"
      className="header-image emoji_story_bot"
    />
    <h1 className="emoji_story_bot">HELP! SEYMOUR CAN'T READ!</h1>
    <p className="emoji_story_bot">
      Seymour the puppy can only read pictures, not words! He's so sad he
      can't read any books, help write a story that he can read!
    </p>
    <p className="emoji_story_bot">
      Type in any emojis- in any sequence- and watch as Seymour creates a
      story out of it!
    </p>
  </header>
  <main className="emoji_story_bot">
    <form id="emoji-form" onSubmit={handleSubmit} className="emoji_story_bot">
      <label htmlFor="emoji-sequence" className="emoji_story_bot">
        Enter your emojis:
      </label>
      <input
        type="text"
        id="emoji-sequence"
        name="emoji-sequence"
        required=""
        className="emoji_story_bot"
      />
      <button type="submit" className="emoji_story_bot">
        Show Seymour your Emojis:
      </button>
    </form>
    <div
      id="story-output"
      className="output-box emoji_story_bot"
      ref={outputElementRef}
      style={{ whiteSpace: "pre-wrap" }}
    >
      {outputText}
    </div>
  </main>
  <footer className="emoji_story_bot">
    <p className="emoji_story_bot">© 2024 Emoji Story API</p>
  </footer>
</div>
  );
};

export default EmojiWebPage;