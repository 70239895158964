import React, { useState, useEffect } from "react";
import {
  useLocation,
  BrowserRouter,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Card from "./Card";
import "../styles.css";

import BarryBeeBensonWebPage from "./webApiProjects/BarryBeeBenson/webPage";
import BackIcon from "./BackIcon";
import EmojiWebPage from "./webApiProjects/EmojiStoryBot/emojiWebPage";

const PortfolioProjectImages = [
  require("../images/webAPIgallery/landing_page_banner.png"),
];

const ChatWebapiGallery = () => {
  const location = useLocation();
  const showCards = location.pathname === "/webapi-gallery";
  const showBack = location.pathname.match(/webapi-gallery\/.*/);

  useEffect(() => {
    document.title = "WebAPI Gallery | AI Core";
  }, []);

  const navigate = useNavigate();
 
  const webAPIprojects = [
    {
      image: require("../components/webApiProjects/BarryBeeBenson/barry-bee-benson-glasses.jpeg"),
      title: "Barry Bee Benson Bot",
      subtitle: "A chatbot that impersonates Barry Bee Benson",
      name: "- Justin",
      route: "barry-bee-benson"
    },
    {
      image: require("../components/webApiProjects/EmojiStoryBot/seymour.png"),
      title: "Emoji Story Bot",
      subtitle: "Seymour can't read, so this chatbot turns emojis into a story",
      name: "- Vivian",
      route: "emoji-story-bot"
    },
    {
      image: require("../components/webApiProjects/FinancialAssistant/card_cover.png"),
      title: "Financial Assistance Bot",
      subtitle: "A chatbot that helps you with your personal finances",
      name: "- Shivom",
      route: ""
    },
    {
      image: require("../components/webApiProjects/OpenAIMultiplePersonalities/Designer.png"),
      title: "A multiple personality friend bot",
      subtitle: "Chat with a wide range of personaly bots",
      name: "- Maia",
      route: ""
    },
    {
      image: require("../components/webApiProjects/VideoGameGPT&Connect4/Designer.png"),
      title: "Our professional videogame bot",
      subtitle: "1v1 our most feared competitor",
      name: "- Hector",
      route: ""
    }
    
  ];

  const handleCardClick = (webAPIproject) => {
    navigate(`/webapi-gallery/${webAPIproject.route}`);
  };

  return (
    <>
      <Routes>
        <Route path="/barry-bee-benson" element={<BarryBeeBensonWebPage />} />
        <Route path="/emoji-story-bot" element={<EmojiWebPage />} />
      </Routes>

      {showCards && (
        <div className="grid-container">
          {webAPIprojects.map((webAPIproject, index) => (
            <Card
              key={index}
              image={webAPIproject.image}
              title={webAPIproject.title}
              subtitle={webAPIproject.subtitle}
              name={webAPIproject.name}
              onClick={() => handleCardClick(webAPIproject)}
            />
          ))}
        </div>
      )}

      {showBack && <BackIcon/>}
    </>
  );
};

export default ChatWebapiGallery;
