import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactPlayer from 'react-player';
import '../styles.css';

const CurrentNews = ({ url, title, bubbleTitle, text, button }) => {
    return (
        <div className="bubble2" style={{ display: "block" }}>
            <div style={{ marginBottom: "10px", width: "100%", aspectRatio: "1.5/1" }}>
                <ReactPlayer style={{ borderRadius: "25px", overflow: "hidden" }} url={url} playing={false} width="100%" height="100%" borderRadius="5px" controls />
            </div>
            <span className="mini_title">{title}</span>
            <div className="bubble_title" style={{ fontSize: "25px", minHeight: "40px" }}>{bubbleTitle}</div>
            <div className="bubble_text">{text}</div>
            <br />
            <button type="button" className="rounded_button_1" onClick={() => { window.location.href = {url}; }}>Learn More</button>
        </div>
    );
}

const Product = ({ title, description, image, link }) => {
    return (
        <div className="bubble1">
            <img style={{ borderRadius: "25px", width: "100%", aspectRatio: "1.33/1" }} src={image} alt="" />
            <div className="bubble_title" style={{ fontSize: "25px", minHeight: "40px" }}>{title}</div>
            <br />
            <div className="bubble_text">{description}</div>
            <br />
            <button type="button" className="rounded_button_1" onClick={() => { window.location.href = link }}>Learn More</button>
        </div>
    );
}

const Home = () => {

    const currentNews = [
        {
            url: "https://www.youtube.com/watch?v=YZp3Hy6YFqY&ab_channel=MatthewBerman",
            title: "KEEPING UP WITH AI",
            bubbleTitle: "AI Agents",
            text: "AI agents are like super-smart digital buddies that are specialized in doing specific tasks. They use fancy tech to figure out what's happening and act, whether it's cracking jokes or driving a car. Think of them as quirky little assistants who make your life easier and more fun, all while learning and adapting as they go!",
            button: "Learn More"
        },
        {
            url: "https://www.youtube.com/watch?v=SBoen3q5AoQ&t=531s",
            title: "ROBOTICS",
            bubbleTitle: "NVIDIA",
            text: "NVIDIA is supercharging robots with brainpower that's out of this world! Their tech helps robots see, learn, think, and act fast & smart, making them perfect for jobs like zipping through traffic or sorting boxes in a blink. Picture a future where robots are like handy pals, always there to lend a high-tech hand with whatever we need!",
            button: "Learn More"
        }
    ] 

    const products = [
        {
            title: "Speaking & Engagement",
            description: "Discover how AI is revolutionizing the world in our interactive workshops. Experience live demonstrations to see first hand how AI can be used to solve problems quickly and efficiently. Join us to explore the transformative power of AI!",
            image: require('../images/AI_Core_Workshop.webp'),
            link: '/speaking'
        },
        {
            title: "The Prompt",
            description: "Keep pace with the rapidly evolving world of AI by subscribing to our monthly magazine The Prompt! See how AI Core is innovatively using and developing AI solutions for our partners. Plus, enjoy brain-teasing puzzles in each issue to keep your mind sharp!",
            image: require('../images/ThePromptMay24.webp'),
            link: '/the-prompt'
        },
        // {
        //     title: "Our Chatbot UI",
        //     description: "Meet AI Core's chatbot, Dev-0, powered by every Foundation Model we can get our hands on. Equipped to assist with a diverse array of tasks our ChatBot UI is home for exploring new models and custom bots as they emerge from industry, and our Bench.",
        //     image: require('../images/CHAT_BOT_GPT_FOR_AICORE.webp'),
        //     link: '/chat'
        // }
    ]
    const data = {
        teamDescription: "A large, fast-moving workforce development program dedicated to getting UArizona undergraduates and graduates versed in the most powerful technologies of today.  Our Summer 2024 Internship brought together over 60 Wildcats for an experiential learning deep dive into GenAI, LLMs, GPTs, RAGs, Agentic Architectures and Emerging Technologies like Unreal Engine 5, Digital Twinning, and NPC AI.  Fully project based, learning doesn't come swifter than this.",
        university: "We are the University of Arizona",
        colleges: [
            { name: "College of Agriculture", people: ["Maya Turner", "Aiden Branigan"] },
            { name: "College of Applied Science and Technology", people: ["Denisse Guerra", "Maia Fraizer"] },
            { name: "College of Architecture", people: ["Manuel Barcelo"] },
            { name: "College of Fine Arts", people: ["Andy Rosas"] },
            { name: "College of Humanities", people: ["Frida Quintana"] },
            { name: "Eller College of Management", people: ["Hector Arce"] },
            { name: "Mel and Enid Zuckerman College of Public Health", people: ["Maryam Heydarifar"] },
            { name: "College of Information Science", people: ["Vivian Huynh", "Nnanyere Okoro", "Danielle Cunes"] },
            { name: "College of Science", people: ["Andy Lu", "Joseph Marbella", "Levi Taylor", "Alex Myers", "Mandy Jiang", "Alexis Byrnes", "Joeli Vakatawa", "Paulina Aguirre", "Nilufer Demirbas", "Shivom Paudel", "Hal Weisman", "Aidan Rains", "Ayanle Noor", "Kyle Becerril"] },
            { name: "College of Engineering", people: ["Annie Asher", "Devin Taylor", "Justin Nguyen", "Kevin Aquino Santiago", "Nicolas Littleman", "Yaolei Bian"] },
        ],
        highlights: [
            "Health Sciences Innovation",
            "The Institute for Computation & Data-Enabled Insight",
            "The Office of Student Engagement and Career Development - DESIGN LAB",
            "University Center for Assessment, Teaching & Technology"
        ],
        awesomeBy: "The University Center for Assessment, Teaching & Technology",
        possibleBy: "Student Engagement and Career Development and The Institute for Computation & Data-Enabled Insight",
        realBy: "Health Sciences Design - thank you so much for the gorgeous space!"
    };

    useEffect(() => {
        document.title = "Home | AI Core";
    }, []);

    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [joinedMailingList, setJoinedMailingList] = useState("");

    // Handles joining the email list
    const handleMailingList = async (e) => {
        e.preventDefault();
        if (!email) { return; }

        try {
            const response = await axios({
                url: `${process.env.REACT_APP_BACKEND_URL}/routes/join-mailing-list`,
                method: 'POST',
                responseType: 'json',
                data: { email: email },
            });
            // join success
            setJoinedMailingList("Joined");
            setEmail("")
        } catch (error) {
            setJoinedMailingList("Error");
        }
    };

    const groupColleges = (colleges) => {
        const groupedColleges = [];
        const groupSize = 3;

        for (let i = 0; i < colleges.length; i += groupSize) {
            groupedColleges.push({
                name: `Group ${Math.floor(i / groupSize) + 1}`,
                colleges: colleges.slice(i, i + groupSize)
            });
        }

        return groupedColleges;
    };

    const groupedColleges = groupColleges(data.colleges.filter(college => college.name !== "College of Science"));

    return (
        <>
            <div className="frontpage" >
                <div className="frontpage_section">
                    <div className="title_box_subtitle" style={{ fontSize: "130px", color: "white" }}>AI CORE</div>
                </div>
            </div>
            <div className="container">
                <div className="title_box_subtitle" style={{ marginTop: "45px", minHeight: "100px", textAlign: "center", fontSize: "50px"}}>
                    AI Core Is
                </div>
                <div className="whoarewe-container" style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", padding: "20px"}}>
                    <div className="image-block" style={{ width: "40%"}}>
                        <img style={{ borderRadius: "25px", width: "100%", objectFit: "cover", height: "auto", aspectRatio: "1.33/1", marginTop: "5px" }} src={require('../images/ai_core_team.jpg')} alt="AI Kid" />
                    </div>
                    <div className="description-block" style={{ width: "55%", marginLeft: "5%" }}>
                        {/* <span className="inner_text" style={{ fontSize: "50px", fontFamily: "ProximaNova-Bold" }}>AI Core is</span><br /><br /> */}
                        <div className="waw-description-container">
                            <div className="waw-speaking_block">
                                <div className="waw-inner_text waw-description" style={{ lineHeight: "2", marginTop: "5px", marginBottom: "10px" }}>
                                    {data.teamDescription}
                                </div><br />
                                <div>
                                    <span className="waw-inner_text waw-description">AI CORE IS also a massive collaboration effort drawing from 10 different colleges and 4 administrative units.  We're proud to raise the bar on what is possible when UArizona works together and pulls in the same direction.  Here's who we really are:</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="waw-college-list-container" style={{ padding: "20px" }}>
                    <div className="waw-college-grid">
                        <div className="waw-college-item">
                            <h3 className="waw-college-title">College of Science</h3>
                            <ul className="waw-people-list">
                                {data.colleges.find(college => college.name === "College of Science").people.map((person, idx) => (
                                    <li className="waw-person" key={idx}>{person}</li>
                                ))}
                            </ul>
                        </div>
                        {groupedColleges.map((group, index) => (
                            <div key={index} className="waw-college-item">
                                {group.colleges.map((college, idx) => (
                                    <div key={idx}>
                                        <h4 className="waw-college-subtitle">{college.name}</h4>
                                        <ul className="waw-people-list">
                                            {college.people.map((person, id) => (
                                                <li className="waw-person" key={id}>{person}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className = "container">
                <div className="waw-highlights-container" style={{ padding: "10px", display: "flex", justifyContent: "center" }}>
                    <div className="waw-speaking_block" style={{ maxWidth: "900px", width: "100%", textAlign: "center" }}>
                        <span className="waw-inner_text waw-title" style={{ fontSize: "30px", fontFamily: "ProximaNova-Bold", textAlign: "center", display: "block", color: "var(--blue)" }}>This innovative collaboration is made POSSIBLE BY:</span><br /><br />
                        <span > {data.possibleBy}</span>
                        <span className="waw-inner_text waw-title" style={{ fontSize: "30px", fontFamily: "ProximaNova-Bold", textAlign: "center", display: "block", color: "var(--blue)", paddingTop: "20px" }}>AI CORE is made more AWESOME BY:</span><br /><br />
                        <span >{data.awesomeBy}</span>
                        <span className="waw-inner_text waw-title" style={{ fontSize: "30px", fontFamily: "ProximaNova-Bold", textAlign: "center", display: "block", paddingTop: "20px", color: "var(--blue)" }}>And for Summer 2024 AI Core was made REAL BY:</span><br /><br />
                        <span >{data.realBy}</span>
                        <div>
                        <img style={{ borderRadius: "15px", width: "100%", height: "auto", maxHeight: "500px", marginTop: "5px", paddingTop: "20px" }} src={require('../images/office_space.jpg')} alt="AI Kid" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="content_block">
                    <div className="content1" style={{ textAlign: "center" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                            {products.map((product, index) => (
                                <Product key={index} title={product.title} description={product.description} image={product.image} link={product.link} />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="content_block">
                    <div className="content1" style={{ textAlign: "center" }}>
                        <div className="title_box_subtitle" style={{ marginBottom: "10px", textAlign: "center" }}>LIVE FROM THE BENCH</div>
                        <div className="title_box_subtitle" style={{ marginBottom: "20px", textAlign: "center", fontSize: "20px" }}>Keep an eye on these fascinating developments in AI and popular posts on our Discord server</div>
                        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                            {currentNews.map((news, index) => (
                                <CurrentNews key={index} url={news.url} title={news.title} bubbleTitle={news.bubbleTitle} text={news.text} button={news.button} />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="content_block" style={{ background: "#e2e9eb" }}>
                    <div className="content">
                        <div className="title_box_subtitle" style={{ marginBottom: "20px" }}>Join Our Mailing List</div>
                        <div className="bubble_text">Stay updated with AI Core and receive our monthly newsletter, The Prompt!</div>
                        <form onSubmit={handleMailingList}>
                            <input type="email" className="form" style={{ marginTop: "30px" }} placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} /><br />
                            <button type="submit" className="button" style={{ marginTop: "15px", marginBottom: "5px" }}>Join the list</button>
                        </form>
                        {joinedMailingList !== "" && <div className="bubble_text" style={{ fontStyle: "italic" }}>{joinedMailingList === "Joined" ? "Thanks for joining our mailing list :)" : "Internal server error."}</div>}
                        {joinedMailingList === "Error" && <div className="bubble_text" style={{ fontStyle: "italic" }}>{`(${process.env.REACT_APP_BACKEND_URL}/routes/join-mailing-list did not return a response)`}</div>}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Home;