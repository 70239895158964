import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const BarryBeeBensonWebPage = () => {
  const [outputText, setOutputText] = useState("");
  const [selectedVoice, setSelectedVoice] = useState(null);
  const conversationHistory = useRef([
    {
      role: "system",
      content:
        "You are cosplaying as Berry B Benson, a character who works bee colony. You respond with knowledge about bees, beekeeping, and related topics, and you always maintain your personality as Berry B Benson. If requested, you can change your personality or even style of talking such as a western style, however you will always remain a bee with a secret love for humans. Try to include emojis where it fits whether it is a bee when the conversation includes the hive",
    },
  ]);
  const outputElementRef = useRef(null);

  useEffect(() => {
    document.title = "Barry Bee Benson | AI Core";

    // Initialize voices when the component mounts
    const initializeVoices = () => {
      const voices = window.speechSynthesis.getVoices();
      console.log("Available voices:", voices);
      setSelectedVoice(
        voices.find((voice) => voice.name === "Google US English Male") ||
          voices[0]
      );
    };

    // Initialize voices when they are loaded
    window.speechSynthesis.onvoiceschanged = initializeVoices;
    initializeVoices(); // Call it once initially in case voices are already loaded
  }, []);

  const navigate = useNavigate();

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      generateResponse();
    }
  };

  const generateResponse = async () => {
    const inputElement = document.getElementById("inputText");
    const inputText = inputElement.value;

    if (inputText.trim() === "") {
      return;
    }

    conversationHistory.current.push({
      role: "user",
      content: inputText,
    });

    setOutputText((prevOutput) => (
      <>
        {prevOutput}
        <p>
          <span className="user">You:</span> {inputText}
        </p>
        <hr />
      </>
    ));

    inputElement.value = "";

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/routes/message-custom-webapi`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            model: "gpt-4", // or 'gpt-3.5-turbo'
            messages: conversationHistory.current,
          }),
        }
      );

      if (response.status === 429) {
        console.error("Too many requests. Please try again later.");
        setOutputText(
          (prevOutput) =>
            `${prevOutput}<p><span class='berry'>Berry B Benson:</span> Too many requests. Please try again later.</p>`
        );
        return;
      }

      const responseData = await response.json();
      
      // console.log(responseData);

      if (responseData && responseData.choices && responseData.choices.length > 0) {
        const botMessage = responseData.choices[0].message.content;

        conversationHistory.current.push({
          role: "assistant",
          content: botMessage,
        });

        const paragraphs = botMessage
          .split("\n")
          .filter((para) => para.trim() !== "");
        setOutputText((prevOutput) => (
          <>
            {prevOutput}
            {paragraphs.map((para, index) => (
              <p key={index}>
                <span className="berry">Berry B Benson:</span> {para}
              </p>
            ))}
          </>
        ));

        // Text-to-Speech
        const speech = new SpeechSynthesisUtterance(botMessage);
        speech.voice = selectedVoice;
        speech.lang = "en-US";
        window.speechSynthesis.speak(speech);
      } else {
        setOutputText("Error: No response data or empty choices array.");
      }
    } catch (error) {
      console.error("Error:", error);
      setOutputText("Error: An error occurred while fetching the response.");
    }
  };

  // Debounce function
  const debounce = (func, delay) => {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const debouncedGenerateResponse = debounce(generateResponse, 1000);

  useEffect(() => {
    // Scroll to bottom after outputText updates
    if (outputElementRef.current) {
      outputElementRef.current.scrollTop =
        outputElementRef.current.scrollHeight;
    }
  }, [outputText]);

  return (
    <>


    <div className="webAPIprojectContainer" style={{
      fontFamily: "'Times New Roman', Times, serif, sans-serif", // Apply font family to the container
      cursor: "url('https://primebees.com/wp-content/uploads/2017/04/bumble-bee.png'), auto" // Apply cursor style
    }}> 
      <title>Who's That? It's Barry B Benson!</title>

      <div className="container" style={{ 
        backgroundImage: "url('https://imgs.search.brave.com/sgeMkvrK0rnepJQEWJeNFlL89FLCEMibpcFUiJ6ntxY/rs:fit:500:0:0/g:ce/aHR0cHM6Ly9pbWFn/ZXMtbmEuc3NsLWlt/YWdlcy1hbWF6b24u/Y29tL2ltYWdlcy9J/LzQxZDZydk0yMEVM/LmpwZw')",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        maxWidth: "80%",
        margin: "0 auto",
        padding: 0
      }}>
        <div className="top-left-image" style={{
          position: "absolute",
          width: "10%",
          height: "10%",
          backgroundSize: "cover",
          backgroundPosition: "center",
          top: "2%",
          left: "2%",
          backgroundImage:
            "url('https://www.shitpostbot.com/img/sourceimages/barry-b-benson-5843af49bf827.jpeg')"
        }} />
        <div className="top-right-image" style={{
          position: "absolute",
          width: "10%",
          height: "10%",
          backgroundSize: "cover",
          backgroundPosition: "center",
          top: "2%",
          right: "2%",
          backgroundImage:
            "url('https://th.bing.com/th/id/OIP.9XK0ul12ba0KDOoab4RQnQHaFN?w=209&h=180&c=7&r=0&o=5&pid=1.7')"
        }} />
        <div> 
          <h1 style={{  /* Example of inline styling */
            textAlign: "center" 
          }}>🐝Barry Bee Benson🐝</h1>
          <blockquote cite="https://www.imdb.com/title/tt0389790/quotes/">
            <em>“So you'll just work us to death?”</em>
            <br /> — Barry B. Benson
          </blockquote>
          <textarea
            id="inputText"
            placeholder="Hello Human What Assistance Do you Require?"
            onKeyDown={handleKeyPress}
            style={{ 
              width: "100%",
              height: "10vh", 
              marginBottom: "5px",
              padding: "3px",
              boxSizing: "border-box",
              backgroundColor: "rgba(50, 50, 50, 0.75)",
              color: "white", 
              border: "1px solid rgba(0, 0, 0, 0.5)" 
            }}
          />
        </div>
        <div id="buttonContainer" style={{
          textAlign: "right",
          padding: 0
        }}>
          <button id="submitButton" onClick={debouncedGenerateResponse} style={{ 
            padding: "10px 20px",
            color: "rgb(218, 223, 195)",
            backgroundColor: "#ffffff",
            border: "none",
            cursor: "pointer",
            fontSize: "24px"
          }}>
            🐝
          </button>
        </div>
        <div
          id="output"
          ref={outputElementRef}
          style={{ 
            width: "100%",
            height: "60vh",
            padding: "3px",
            marginTop: "20px",
            backgroundColor: "rgba(50, 50, 50, 0.75)",
            color: "white", 
            textAlign: "left",
            overflowY: "scroll",
            borderRadius: "2px",
            boxSizing: "border-box",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "rgba(0, 0, 0, 0.5)" 
          }}
        >
          {outputText}
        </div>
      </div>
    </div>
    </>
  );
};

export default BarryBeeBensonWebPage;