import React, { useEffect, useRef, useState } from "react";
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
import BrainCanvas from "./BrainCanvas";
import RetailXRCanvas from "./RetailXRCanvas";
import ReactPlayer from "react-player";
import "../../styles.css";
import "./WildcatWelcome.css";
import HealthXRCanvas from "./HealthXRCanvas";
// Import the image you uploaded
import dune from "../../images/dune4.webp";
import LandingPage from "./LandingPage";

// gsap.registerPlugin(ScrollTrigger);

// Might change. This is an irresponsive component
// const CustomCursor = () => {
//   const [position, setPosition] = useState({ x: 0, y: 0 });

//   useEffect(() => {
//     const moveCursor = (e) => {
//       setPosition({ x: e.clientX, y: e.clientY });
//     };

//     window.addEventListener("mousemove", moveCursor);
//     return () => window.removeEventListener("mousemove", moveCursor);
//   }, []);

//   return (
//     <div
//       className="custom-cursor"
//       style={{ left: `${position.x}px`, top: `${position.y}px` }}
//     />
//   );
// };

const WildcatWelcome = () => {
  const scienceXR = useRef(null);
  const retailXR = useRef(null);
  const healthXR = useRef(null);

  const [hoveredProject, setHoveredProject] = useState(null);
  const [isListHovered, setIsListHovered] = useState(false);

  // Scroll to the clicked project
  const scrollToClickProject = (name) => {
    let ref = null;
    switch (name) {
      // Add more projects if needed here
      case "Scientific XR Visualization Platform":
        ref = scienceXR;
        break;
      case "Retail Sciences XR/AI Lab":
        ref = retailXR;
        break;
      case "XR for Health Innovation":
        ref = healthXR;
        break;
      default:
        break;
    }
  
    // Check if ref is not null before scrolling
    if (ref !== null) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }


  const projectNames = [
    "Scientific XR Visualization Platform",
    "Retail Sciences XR/AI Lab",
    "Pima County Visitor's Center NPC AI",
    "Vault Learning",
    "Joyful Jobs",
    "Radian",
    "XR for Health Innovation",
    "City of Tucson Chatbot",
    "Cybersecurity CTF Gaming League",
    "The Play District",
  ];

  const projectImages = {
    "Scientific XR Visualization Platform": "/path/to/scientific_xr.jpg",
    "Retail Sciences XR/AI Lab": "/path/to/retail_xr.jpg",
    "Pima County Visitor's Center NPC AI": "/path/to/project3.jpg",
    "Vault Learning": "/path/to/project4.jpg",
    "Joyful Jobs": "/path/to/project5.jpg",
    "Radian": "/path/to/project6.jpg",
    "XR for Health Innovation": "/path/to/health_innovation.jpg",
    "City of Tucson Chatbot": "/path/to/project8.jpg",
    "Cybersecurity CTF Gaming League": "/path/to/project9.jpg",
    "The Play District": "/path/to/project10.jpg",
  };

  // useEffect(() => {
  //   gsap.fromTo(
  //     ".intro-project-name",
  //     { opacity: 0, y: 20 },
  //     {
  //       opacity: 1,
  //       y: 0,
  //       duration: 1,
  //       stagger: 0.2,
  //       ease: "power3.out",
  //     }
  //   );
  // }, []);

  return (
    <>

    <LandingPage />
    
    <div className="wildcat-welcome">
      <section
        className={`intro-section ${isListHovered ? 'hovered' : ''}`}
        style={{
          backgroundImage: `url(${dune})`,
        }}
      >
        <div className="intro-content">
          <div
            className="intro-project-list"
            onMouseEnter={() => setIsListHovered(true)}
            onMouseLeave={() => {
              setIsListHovered(false);
              setHoveredProject(null); // Reset the hovered project on list exit
            }}
          >
            {projectNames.map((name) => (
              <div
                key={name}
                className="intro-project-name"
                onMouseEnter={() => setHoveredProject(name)}
                onClick = {() =>scrollToClickProject(name)}
              >
                {name}
              </div>
            ))}
          </div>
        </div>
      </section>

      <section ref={scienceXR} className="section project-section">
        <h2>Scientific XR Visualization Platform</h2>
        <BrainCanvas />
        <p className="project-text">
          Discover the cutting-edge world of interactive brain scans with the
          Scientific XR team. Partnering with the Catalyst Studios at the UA
          Main Library and scientists at the University of Arizona, this project
          brings real scientific data into an immersive 3D environment using
          Unreal Engine. These brain scans, placed in extended reality, are
          ready to be experienced using the Meta Quest 3. Stay tuned for more
          updates!
        </p>
      </section>

      <section ref={retailXR} className="section project-section">
        <h2>Retail Sciences XR/AI Lab</h2>
        <RetailXRCanvas />
        <ReactPlayer
          url="https://vimeo.com/999964422?share=copy"
          playing={true}
          loop={true}
          muted={true}
          className="xr-health"
          width="100%"
          height="100%"
        />
        <p className="project-text">
          Revolutionizing the shopping experience, this project invents next-generation \
	  Augmented Reality (AR) experiences and explores AI in a realistic retail space.\
	  By integrating VR, Digital Twinning, Computer Vision, and Agentic LLM structures,\
	  the team creates a dynamic and immersive retail environment that showcases the\
	  potential of AI and XR in retail sciences.
	</p>
      </section>

      <section ref={healthXR} className="section project-section">
        <h2>XR for Health Innovation</h2>
        <HealthXRCanvas />
        <ReactPlayer
          url="https://vimeo.com/1000207758?share=copy"
          playing={true}
          loop={true}
          muted={true}
          className="xr-health"
          width="100%"
          height="100%"
        />
        <p className="project-text">
          Professor Matthew Briggs of the University of Arizona Health Sciences
          Design leads development this summer in pursuit of a novel techniques
          leveraging LLM and Computer Vision models to create convincing PBR
          Textures for medical simulation. By pioneering physically based
          rendering, the team will create a "text- to-texture" modality for AI -
          bringing to life highly realistic avatars and NPCs in real-time.
        </p>
      </section>
    </div>

  </>
  );
};

export default WildcatWelcome;
