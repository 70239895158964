import React, { useState, useEffect } from "react";
import axios from "axios";

import prompt1 from '../prompt/The_Prompt_Issue_1_-_January_2024.pdf';
import prompt2 from '../prompt/The_Prompt_Issue_2_-_February_2024.pdf';
import prompt3 from '../prompt/The_Prompt_Issue_3_-_March_2024.pdf';
import prompt4 from '../prompt/The_Prompt_-_Issue_4.pdf';
import prompt5 from '../prompt/The_Prompt_-_Issue_5.pdf';

import titleImage from '../images/paper.jpg';
import '../styles.css';

const ThePrompt = () => {

    useEffect(() => {
        document.title = "The Prompt | AI Core";
    }, []);

    const [email, setEmail] = useState("");
    const [joinedMailingList, setJoinedMailingList] = useState("");

    // Handles joining the email list
    const handleMailingList = async (e) => {
        e.preventDefault();
        if (!email) { return; }

        try {
            const response = await axios({
                url: `${process.env.REACT_APP_BACKEND_URL}/routes/join-mailing-list`,
                method: 'POST',
                responseType: 'json',
                data: { email: email },
            });

            // join success
            setJoinedMailingList("Joined");
            setEmail("")
        } catch (error) {
            setJoinedMailingList("Error");
        }
    };
    
    return (
        <>
        <div className="container">

            <div className="animated_bg" style={{ backgroundImage: `url(${titleImage})`}}>
                <div className="animated_title">The Prompt</div>
            </div>

            <div className="content_block" style={{ background: "var(--blue)" }}>
                <div className="content">
                    <div className="title_box_subtitle" style={{ marginBottom: "20px", color: "white" }}>Stay Informed</div>

                    <div className="bubble_text" style={{ color: "white" }}>Enter your email and we'll send new issues straight to your inbox each month!</div>
                    
                    <form onSubmit={handleMailingList}>
                        <input type="email" className="form" style={{ marginTop: "30px" }} placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)}/><br/>
                        
                        <button type="submit" className="button" style={{ marginTop: "15px", marginBottom: "5px" }}>Get access</button>
                    </form>

                    {joinedMailingList !== "" && <div className="bubble_text" style={{ fontStyle: "italic" }}>{joinedMailingList === "Joined" ? "Thanks for joining our mailing list :)" : "Internal server error."}</div>}
                    {joinedMailingList === "Error" && <div className="bubble_text" style={{ fontStyle: "italic" }}>{`(${process.env.REACT_APP_BACKEND_URL}/routes/join-mailing-list did not return a response)`}</div>}
                </div>
            </div>

            <div className="content_block">
                <div className="content">
                        <div className="title_box_subtitle" style={{ marginBottom: "20px" }}>Recent Newsletters</div>

                        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>

                        <div className="bubble">
                            <div className="bubble_title">Agentic Workflows</div>
                            <div className="tag">May 2024</div>
                            <div className="bubble_text">Explore the world of Agentic Workflows.</div>

                            <a href={prompt5} target="_blank" rel="noopener noreferrer">
                                <img className="prompt_cover" src={require('../images/ThePromptMay24.webp')} alt=""/>
                            </a><br/>

                            <a href={prompt5} download="The_Prompt_Issue_5_-_May_2024.pdf" className="button" style={{ display: "inline-block", paddingTop: "11px", boxSizing: "border-box" }}><i className="material-symbols-outlined inline-icon" style={{ color: "white" }}>download</i> Download</a>
                        </div>

                        <div className="bubble">
                            <div className="bubble_title">The Road to AGI</div>
                            <div className="tag">April 2024</div>
                            <div className="bubble_text">Follow major steps taken towards building artificial general intelligence.</div>

                            <a href={prompt4} target="_blank" rel="noopener noreferrer">
                                <img className="prompt_cover" src={require('../prompt/prompt4.png')} alt=""/>
                            </a><br/>

                            <a href={prompt4} download="The_Prompt_Issue_4_-_April_2024.pdf" className="button" style={{ display: "inline-block", paddingTop: "11px", boxSizing: "border-box" }}><i className="material-symbols-outlined inline-icon" style={{ color: "white" }}>download</i> Download</a>
                        </div>

                        <div className="bubble">
                            <div className="bubble_title">AI-Generated Avatars</div>
                            <div className="tag">March 2024</div>
                            <div className="bubble_text">Discover the leading edge of AI avatar technology.</div>

                            <a href={prompt3} target="_blank" rel="noopener noreferrer">
                                <img className="prompt_cover" src={require('../prompt/prompt3.png')} alt=""/>
                            </a><br/>

                            <a href={prompt3} download="The_Prompt_Issue_3_-_March_2024.pdf" className="button" style={{ display: "inline-block", paddingTop: "11px", boxSizing: "border-box" }}><i className="material-symbols-outlined inline-icon" style={{ color: "white" }}>download</i> Download</a>
                        </div>

                        <div className="bubble">
                            <div className="bubble_title">GPTs & Business</div>
                            <div className="tag">February 2024</div>
                            <div className="bubble_text">Use the GPT store to boost productivity for small business.</div>

                            <a href={prompt2} target="_blank" rel="noopener noreferrer">
                                <img className="prompt_cover" src={require('../prompt/prompt2.png')} alt=""/>
                            </a><br/>

                            <a href={prompt2} download="The_Prompt_Issue_2_-_February_2024.pdf" className="button" style={{ display: "inline-block", paddingTop: "11px", boxSizing: "border-box" }}><i className="material-symbols-outlined inline-icon" style={{ color: "white" }}>download</i> Download</a>
                        </div>

                        <div className="bubble">
                            <div className="bubble_title">Generative AI in Marketing</div>
                            <div className="tag">January 2024</div>
                            <div className="bubble_text">Learn the best AI tools for marketing professionals.</div>

                            <a href={prompt1} target="_blank" rel="noopener noreferrer">
                                <img className="prompt_cover" src={require('../prompt/prompt1.png')} alt=""/>
                            </a><br/>

                            <a href={prompt1} download="The_Prompt_Issue_1_-_January_2024.pdf" className="button" style={{ display: "inline-block", paddingTop: "11px", boxSizing: "border-box" }}><i className="material-symbols-outlined inline-icon" style={{ color: "white" }}>download</i> Download</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default ThePrompt;