import React, { useState } from 'react';
import "./finStyles.css"
const FinAssistanceWebPage = () => {
  const [outputText, setOutputText] = useState("");
  const [inputText, setInputText] = useState("");
  const [conversationHistory, setConversationHistory] = useState([
    {
      role: 'system',
      content: "You are a financial advisor chatbot. Provide accurate and helpful financial advice."
    },
    {
      role: 'system',
      content: "Here are some basic financial tips:\n1. Diversify your investments.\n2. Keep an emergency fund.\n3. Invest for the long term."
    }
  ]);

  const fetchStockPrice = async (symbol) => {
    const apiKey = 'RZ0ULD21OVM265P6';
    const url = `https://www.alphavantage.co/query?function=TIME_SERIES_INTRADAY&symbol=${symbol}&interval=1min&apikey=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data['Time Series (1min)']) {
        const lastRefreshed = data['Meta Data']['3. Last Refreshed'];
        const latestData = data['Time Series (1min)'][lastRefreshed];
        const stockPrice = latestData['1. open']; // Using the opening price for the latest interval
        return `The current stock price of ${symbol} is $${stockPrice}.`;
      } else {
        return `Could not fetch the stock price for ${symbol}. Please check the symbol and try again.`;
      }
    } catch (error) {
      console.error('Error fetching stock price:', error);
      return 'Error fetching stock price. Please try again later.';
    }
  };

  const generateResponse = async () => {
    if (inputText.trim() === "") {
      return; // Avoid sending empty messages
    }

    setOutputText((prevText) => prevText + "User: " + inputText + "\n\n");

    const newConversationHistory = [
      ...conversationHistory,
      {
        role: 'user',
        content: inputText
      }
    ];

    setConversationHistory(newConversationHistory);
    setInputText("");

    // Check if the input is asking for a stock price
    const stockPriceMatch = inputText.match(/stock price of (\w+)/i);
    if (stockPriceMatch) {
      const stockSymbol = stockPriceMatch[1].toUpperCase();
      const stockPriceResponse = await fetchStockPrice(stockSymbol);
      setOutputText((prevText) => prevText + "Assistant: " + stockPriceResponse + "\n\n");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/routes/message-gpt4`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem("token")}`
        },
        body: JSON.stringify({
          model: 'gpt-4o',
          messages: newConversationHistory,
        })
      });

      if (response.status === 429) {
        // Too many requests error
        console.error('Too many requests. Please try again later.');
        setOutputText((prevText) => prevText + "Bot: Too many requests. Please try again later.\n\n");
        return;
      }

      const responseData = await response.json();
      console.log(responseData);

      if (responseData && responseData.choices && responseData.choices.length > 0) {
        setOutputText((prevText) => prevText + "Assistant: " + responseData.choices[0].message.content + "\n\n");
      } else {
        setOutputText("Error: No response data or empty choices array.");
      }
    } catch (error) {
      console.error('Error:', error);
      setOutputText("Error: An error occurred while fetching the response.");
    }
  };

  // Debounce function to limit the rate of requests
  const debounce = (func, delay) => {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const debouncedGenerateResponse = debounce(generateResponse, 100);

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      debouncedGenerateResponse();
    }
  };

  const handleSubmitClick = () => {
    debouncedGenerateResponse();
  };

  return (
    <div className="outer-container">
      <div className="container-shivom">
        <div>
          <div className="output">
            {outputText}
          </div>
        </div>
        <div>
          <textarea className="textarea-shivom"
            id="inputText"
            placeholder="Ready to fulfill your financial needs"
            value={inputText}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
          />
          <button onClick={handleSubmitClick}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default FinAssistanceWebPage;
