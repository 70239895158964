import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import titleImage from '../images/mainframe.png';
import '../styles.css';

const WorkWithUs = () => {

    const navigate = useNavigate();
    useEffect(() => {
        document.title = "Work with Us | AI Core";
    }, []);

    const [email, setEmail] = useState("");
    const [position, setPosition] = useState("AI Engineer");
    const [major, setMajor] = useState("");
    const [graduationTerm, setGraduationTerm] = useState("Spring");
    const [graduationYear, setGraduationYear] = useState("2024");
    const [url, setUrl] = useState("");
    const [resumeFile, setResume] = useState(null);
    const [cvFile, setCv] = useState(null);
    const [summer, setSummer] = useState(false);
    const [time, setTime] = useState(false);
    const [valid, setValid] = useState(false);
    const [applied, setApplied] = useState("");
    const [isExpanded, setIsExpanded] = useState(false);

    const [resumeName, setResumeName] = useState("");
    const [cvName, setCvName] = useState("");

    // Expands job description
    const toggleDescription = () => {
        setIsExpanded(!isExpanded);
      };
    
    // Handles applying for a job
    const handleApply = async (e) => {
        e.preventDefault();

        // Custom validation for the file inputs
        if (!resumeFile || !cvFile) {
            alert('Please upload both your resume and cover letter.');
            return;
        }

        if (!summer || !time || !valid) {
            alert('Please agree to all the terms.');
            return;
        }

        const graduation = graduationTerm + graduationYear;

        // Appends data to formData to be sent to the backend
        const formData = new FormData();
        formData.append('email', email);
        formData.append('position', position);
        formData.append('major', major);
        formData.append('graduation', graduation);
        formData.append('url', url);
        formData.append('resume', resumeFile);
        formData.append('cv', cvFile);

        try {
            const response = await axios({
                url: `${process.env.REACT_APP_BACKEND_URL}/routes/apply-for-job`,
                method: 'POST',
                responseType: 'json',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            
            alert('Application submitted!');

            // Application success
            setApplied("Applied");
            setEmail("");
            setPosition("AI Engineer");
            setMajor("");
            setGraduationTerm("Spring");
            graduationYear("2024");
            setUrl("");
            setResume(null);
            setCv(null);
            handleModalPopup();
        } catch (error) {
            setApplied("Error");
        }
    };

    // Triggers resume file input
    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
    };

    // Triggers cv file input
    const triggerCvInput = () => {
        document.getElementById('cvInput').click();
    };

    // Handles file input change
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setResume(file);
            setResumeName(file.name);
        }
    };

    // Handles cv input change
    const handleCvChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setCv(file);
            setCvName(file.name);
        }
    };

    const handleModalPopup = () => {
        document.querySelector('.modal').style.visibility = 'visible';
    }
    
    return (
        <>
        <div className="animated_bg" style={{ backgroundImage: `url(${titleImage})`}}>
            <div className="animated_title">Jobs at AI Core</div>
        </div>

        <div className="content_block" style={{ background: "#e2e9eb" }}>
            <div className="content">
                <div className="title_box_subtitle" style={{ marginBottom: "20px" }}>Apply Now</div>

                <div className="bubble_text">Interested in joining AI Core? Fill out this form.</div>
                
                <form onSubmit={handleApply} style={{ marginTop: "5px", display: "flex", flexDirection: "column" }}>
                    <div className="form_label">Email address<span>*</span></div>
                    <input type="email" className="form" placeholder="wilburwildcat@arizona.edu" value={email} onChange={(e) => setEmail(e.target.value)} required/>

                    <div className="form_label">What position are you applying for?</div>
                    <select value={position} onChange={(e) => setPosition(e.target.value)} className="form">
                        <option value="AI Engineer">AI Engineer</option>
                        <option value="Coder">Coder</option>
                        <option value="Marketer">Marketer</option>
                        <option value="Product Manager">Product Manager</option>
                        <option value="XR Specialist">XR Specialist</option>
                        <option value="UI/IX Designer">UI/UX Designer</option>
                        <option value="Logistics Specialist">Logistics Specialist</option>
                        <option value="Videographer">Videographer</option>
                        <option value="Digital Marketing Specialist">Digital Marketing Specialist</option>
                        <option value="Sales Manager">Sales Manager</option>
                    </select><br/>

                    {position === "AI Engineer" && <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                        <div className="bubble_jd">
                            <div className="bubble_title">Job description:</div>
                            <div className={`jd_text ${isExpanded ? '' : 'collapsed'}`}>
                                The AI Core student engagement program at the University of Arizona is hiring for the position of AI Engineer. This work involves rapid learning, exploration, deployment, development, and innovation in a team environment with Generative AI, LLMs, Machine Learning, NLP, RAG, cognitive services and other aspects of the artificial intelligence revolution. The AI Engineer will be joining a team of dedicated technologists, creatives, business managers, and entrepreneurs to build the innovate AI Core programming and services team. Project work is focused on innovation and the dissemination of AI capability throughout the Arizona Business Community. The AI Core is fundamentally a workforce development program dedicated to moving the Arizona economy into the AI era.<br/><br/>
                                Preferred Requirements<br/>
                                We are looking for candidates with demonstrable experience in the use of AI tools and methodologies including use of ChatGPT, the OpenAI API and GPT store, MidJourney, Dall-E, Runway.ml, Adobe Firefly, or any other tool. We also value academic and research experiences, please highlight in your cover letter. During the application process please provide a URL demonstrative of your work or experience. An information science Major may be preferred for this position but we encourange students of all backgrounds to apply if you have already begun experiment with AI. Please state your Major clearly in your cover letter. If you are highly interested in this position but feel that you do not have enough prior experience, spend the weekend YouTubing and get back to us on Monday with a demo – you can do it.<br/><br/>
                                Mandatory Requirements<br/>
                                •   You must be a student at the University of Arizona (undergraduate or graduate) with an expected graduation date of no earlier than December 2024.<br/>
                                •   You must be eligible and eager to work 13 hours per week during the Spring, Summer, and Fall semesters. It is especially important that you are able to work during the Summer as you’ll be supporting a massive cohort of interns who will be relying on you for AI instruction and expertise.<br/>
                                •   Candidates must be able to work in a hybrid environment featuring both in-person and asynchronous/online work environments.<br/><br/>
                                Compensation: This position pays $15/hr. The AI Core will advocate strongly for your individualized learning and career development. It is also super fun.
                            </div>

                            <button onClick={toggleDescription} className="button_outlined" style={{ height: "35px", paddingLeft: "15px", paddingRight: "15px", marginTop: "10px" }}>{isExpanded ? 'Read Less' : 'Read More'}</button>
                        </div>
                    </div>}

                    {position === "Coder" && <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                        <div className="bubble_jd">
                            <div className="bubble_title">Job description:</div>
                            <div className={`jd_text ${isExpanded ? '' : 'collapsed'}`}>
                                The AI Core at the University of Arizona is hiring for the position of Coder. This work involves rapid learning and Web applications development in a React/Node.js environment with a heavy emphasis on team work, agility, and adoption of Generative AI for low/no code solutions. The Coder also manages complex and scalable information architectures including chatbots, APIs, our own CMS, data stores, and sandboxes in Azure, AWS, and University of Arizona compute environments. The Coder will be joining a team of dedicated technologists, creatives, business managers, and entrepreneurs to build the innovate AI Core programming and services team. Project work is focused on innovation and the dissemination of AI capability throughout the Arizona Business Community. The AI Core is fundamentally a workforce development program dedicated to moving the Arizona economy into the AI era. A fundamental responsibility in addition to acquiring knowledge and skill in AI is sharing and teaching other teammates and the very large summer student cohort.<br/><br/>
                                Preferred Requirements<br/>
                                We are looking for candidates with demonstrable experience in the use of React, Node.js, JavaScript, and CSS.  Understanding of SQL databases a plus.  Experience with Generative AI tools such as ChatGPT, the OpenAI API and GPT store, MidJourney, Dall-E, Runway.ml, Adobe Firefly, or similar Generative AI tools also a plus.<br/><br/>
                                •   We value academic and research experiences; please highlight them in your cover letter.<br/>
                                •   Please provide a URL demonstrative of your work or experience.<br/>
                                •	An information science Major may be preferred for this position, but we encourage students of all backgrounds to apply if they have already begun experimenting with AI.<br/>
                                •	Please state your Major clearly in your cover letter.<br/><br/>
                                Mandatory Requirements<br/>
                                •	You must be a student at the University of Arizona (either an incoming Freshman, Undergraduate, or Graduate) with an expected graduation date of no earlier than December 2024.<br/>
                                •	You must be eligible and eager to work 13 hours per week during the Spring, Summer, and Fall semesters.<br/>
                                •	It is especially important that you are able to work during the Summer as you’ll be supporting a massive cohort of interns who will be relying on you for AI instruction and expertise.<br/>
                                •	Candidates must be able to work in a hybrid environment featuring both in-person and asynchronous/online work environments.<br/><br/>
                                Compensation: This position pays $15/hr. The AI Core will advocate strongly for your individualized learning and career development. It is also super fun.
                            </div>

                            <button onClick={toggleDescription} className="button_outlined" style={{ height: "35px", paddingLeft: "15px", paddingRight: "15px", marginTop: "10px" }}>{isExpanded ? 'Read Less' : 'Read More'}</button>
                        </div>
                    </div>}

                    {position === "XR Specialist" && <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                        <div className="bubble_jd">
                            <div className="bubble_title">Job description:</div>
                            <div className={`jd_text ${isExpanded ? '' : 'collapsed'}`}>
                                The AI Core at the University of Arizona is hiring for the position of XR Dev. This work involves rapid learning and XR (VR, AR, Mixed Reality) applications development in an Unreal Engine 5 environment with a heavy emphasis on team work, exploration, and adoption of Generative AI for low/no code solutions. The XR Dev also supports the University of Arizona XR Collaborative and pioneers complex/frontier development challenges including game development, cinematic rendering, spatial computing, our own scientific visualization platform, data  stores, and multiplayer experiences. The XR Dev will be joining a team of dedicated technologists, creatives, business managers, and entrepreneurs to build the innovate AI Core programming and services team. Project work is focused on innovation and the dissemination of AI capability throughout the University of Arizona Research and State of Arizona Business Communities. The AI Core is fundamentally a workforce development program dedicated to moving the Arizona economy into the AI era. A fundamental responsibility in addition to acquiring knowledge and skill in AI is sharing and teaching other teammates and the very large summer student cohort.<br/><br/>
                                Preferred Requirements<br/>
                                We are looking for candidates with demonstrable experience in the creation of immersive experiences in Unreal Engine 5, Unity, 8th Wall, A-Frame, Blender, or other 3D/Spatial computing tools.  Understanding of React/Node and/or Web architectures a plus.  Understanding of databases a plus.  Experience with Generative AI tools such as ChatGPT, the OpenAI API and GPT store, MidJourney, Dall-E, Runway.ml, Adobe Firefly, or similar Generative AI tools also a major boost.<br/><br/>
                                •	We value academic and research experiences; please highlight them in your cover letter.<br/>
                                •	Please provide a URL demonstrative of your work or experience.<br/>
                                •	An information science Major may be preferred for this position, but we encourage students of all backgrounds to apply if they have already begun experimenting with AI.<br/>
                                •	Please state your Major clearly in your cover letter.<br/><br/>
                                Mandatory Requirements<br/>
                                •	You must be a student at the University of Arizona (either an incoming Freshman, Undergraduate, or Graduate) with an expected graduation date of no earlier than December 2024.<br/>
                                •	You must be eligible and eager to work 13 hours per week during the Spring, Summer, and Fall semesters.<br/>
                                •	It is especially important that you are able to work during the Summer as you’ll be supporting a massive cohort of interns who will be relying on you for AI instruction and expertise.<br/>
                                •	Candidates must be able to work in a hybrid environment featuring both in-person and asynchronous/online work environments.<br/><br/>
                                Compensation: This position pays $15/hr. The AI Core will advocate strongly for your individualized learning and career development. It is also super fun.
                            </div>

                            <button onClick={toggleDescription} className="button_outlined" style={{ height: "35px", paddingLeft: "15px", paddingRight: "15px", marginTop: "10px" }}>{isExpanded ? 'Read Less' : 'Read More'}</button>
                        </div>
                    </div>}

                    <div className="form_label">What's your major?<span>*</span></div>
                    <input type="text" className="form" value={major} onChange={(e) => setMajor(e.target.value)} required/>

                    <div className="form_label">Expected graduation date</div>
                    <div style={{ display: "flex", width: "400px" }}>
                        <select value={graduationTerm} onChange={(e) => setGraduationTerm(e.target.value)} className="form" style={{ marginRight: "10px" }}>
                            <option value="Spring">Spring</option>
                            <option value="Summer">Summer</option>
                            <option value="Fall">Fall</option>
                            <option value="Winter">Winter</option>
                        </select>

                        <select value={graduationYear} onChange={(e) => setGraduationYear(e.target.value)} className="form">
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                        </select>
                    </div>

                    <div className="form_label">Resume<span>*</span></div>
                    <input type="file" name="resume" id="fileInput" style={{ display: 'none' }} onChange={handleFileChange} accept=".pdf,.doc,.docx"/>
                    <button type="button" className="button_outlined" onClick={triggerFileInput}><i className="material-symbols-outlined inline-icon" style={{ color: "var(--red)" }}>upload</i> Upload File</button>
                    {resumeName && <span style={{ marginTop: "10px", fontSize: "16px" }}>{resumeName}</span>}

                    <div className="form_label">Cover letter<span>*</span></div>
                    <input type="file" name="cv" id="cvInput" style={{ display: 'none' }} onChange={handleCvChange} accept=".pdf,.doc,.docx"/>
                    <button type="button" className="button_outlined" onClick={triggerCvInput}><i className="material-symbols-outlined inline-icon" style={{ color: "var(--red)" }}>upload</i> Upload File</button>
                    {cvName && <span style={{ marginTop: "10px", fontSize: "16px" }}>{cvName}</span>}

                    <div className="form_label">Link to your work<span></span></div>
                    <input type="text" className="form" value={url} onChange={(e) => setUrl(e.target.value)}/>
                    <span style={{ fontSize: "14px", fontStyle: "italic", marginTop: "10px" }}>Share an optional link to a page showcasing your work.</span>

                    <div style={{ marginTop: "25px", display: "flex", alignItems: "center" }}>
                        <input type="checkbox" checked={summer} onChange={(e) => setSummer(e.target.checked)} />
                        <span style={{ marginLeft: "5px", fontSize: "16px", fontStyle: "italic" }}>I am available to work over the summer</span>
                    </div>

                    <div style={{ marginTop: "15px", display: "flex", alignItems: "center" }}>
                        <input type="checkbox" checked={time} onChange={(e) => setTime(e.target.checked)} />
                        <span style={{ marginLeft: "5px", fontSize: "16px", fontStyle: "italic" }}>I can work at least 13 hours/week</span>
                    </div>

                    <div style={{ marginTop: "15px", display: "flex", alignItems: "center" }}>
                        <input type="checkbox" checked={valid} onChange={(e) => setValid(e.target.checked)} />
                        <span style={{ marginLeft: "5px", fontSize: "16px", fontStyle: "italic" }}>I am authorized to work in the US</span>
                    </div>
                    
                    <button type="submit" className="button" style={{ marginTop: "25px", marginBottom: "15px" }}>Submit Application</button>
                </form>

                <div className="modal">
                    <div className="modal_card">
                        <h1 style={{ fontSize: "50px", fontFamily: "ProximaNova-Bold" }}>Application Submitted</h1>
                        <p style={{ fontSize: "20px", lineHeight: "1.5" }}>Thank you for submitting your application to AI Core. We will review your application and get back to you shortly.</p>
                        <button type="button" className="rounded_button" onClick={() => navigate("/")} style={{ marginTop: "20px" }}>Return to Home</button>
                    </div>
                </div>
                {/* {applied !== "" && <div className="bubble_text" style={{ fontStyle: "italic" }}>{applied === "Applied" ? "Boom" : "Internal server error."}</div>}
                {applied === "Error" && <div className="bubble_text" style={{ fontStyle: "italic" }}>{`(${process.env.REACT_APP_BACKEND_URL}/routes/apply-for-job did not return a response)`}</div>} */}
            </div>
        </div>
        </>
    );
}

export default WorkWithUs;