import React, { Suspense, useEffect, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Preload, useGLTF } from '@react-three/drei'
import CanvasLoader from '../Loader'


const gltfModels = ['./HandGLTFV4/StaticMesh.gltf','./MelanomaGLTF_V3/APPROX_StaticMesh.gltf']

const ModelScans = ({ isMobile, gltfModelIndex }) => {
  const gltfModel = useGLTF(gltfModels[gltfModelIndex]);
  return (
    <mesh>
        <spotLight 
        position={[0, 10, 10]}
        angle={Math.PI/2}
        penumbra={1}
        intensity={100}
        castShadow
        decay={0.5}
        shadow-mapSize={1024}
      />
      <hemisphereLight position={[0, 10, 10]} intensity={5} groundColor="white" />

      <primitive 
        object={gltfModel.scene}
        scale={isMobile ? 0.02 : 0.03}
        position={isMobile ? [0,0,0] : [0, 5, 0]} 
        rotation={[0,0,0]}
      />
    </mesh>
  )
}

const HealthXRCanvas = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);

    const handleMediaQueryChange = (e) => {
      setIsMobile(e.matches);
    }

    mediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => mediaQuery.removeEventListener('change', handleMediaQueryChange);
  
  }, []);

  return (
    <div style={{ position: 'relative', width: isMobile ? '20em' : '40em', height: '20rem', overflow: 'hidden' }}>
      <Canvas
        frameloop="demand"
        shadows
        camera={{ position: [20, 3, 5], fov: 70 }}
        gl={{ preserveDrawingBuffer: true }}
        style={{ position: 'absolute', left:"10%", width: '80%', height: '100%'}}
        
      >
        <Suspense fallback={<CanvasLoader />}>
          <OrbitControls
            enableZoom={true}
            autoRotate
            enablePan
          />

          <ModelScans isMobile={isMobile} gltfModelIndex={1} />

        </Suspense>
        <Preload all />
      </Canvas>
    </div>
  )
}

export default HealthXRCanvas;
