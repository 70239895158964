import React, { useState, useEffect } from 'react';
import './ai_web.css';

const MultiPersonalities = () => {
    const [conversationHistory, setConversationHistory] = useState([]);
    const [selectedFriend, setSelectedFriend] = useState(null);
    const [chatVisible, setChatVisible] = useState(false);
    const [settingsVisible, setSettingsVisible] = useState(false);
    const [newFriendName, setNewFriendName] = useState('');
    const [friendItems, setFriendItems] = useState(['pirate', 'grandma', 'surfer']);
    const [bgColor, setBgColor] = useState('#f0f0f0');
    const [font, setFont] = useState('Roboto, sans-serif');
    const [bgImage, setBgImage] = useState(null);

    const apiKey = 'Bearer sk-proj-120SacAQjM0SgfRh04BCT3BlbkFJYgfvWVnPdsLh9QPfJ4OK';
    const apiEndpoint = 'https://api.openai.com/v1/chat/completions';

    useEffect(() => {
        setConversationHistory([{
            role: 'system',
            content: "Respond in the specified manner."
        }]);
    }, []);

    const selectFriend = (friend) => {
        setSelectedFriend(friend);
        setConversationHistory([{
            role: 'system',
            content: `You are a ${friend}. Respond in character as a ${friend}.`
        }]);
        setChatVisible(true);
    };

    const addNewFriend = () => {
        if (newFriendName.trim()) {
            setFriendItems([...friendItems, newFriendName.toLowerCase().replace(/\s/g, '')]);
            setNewFriendName('');
        }
    };

    const generateResponse = async (inputText) => {
        if (!inputText.trim()) return;

        const updatedConversationHistory = [
            ...conversationHistory,
            { role: 'user', content: inputText }
        ];
        setConversationHistory(updatedConversationHistory);

        try {
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': apiKey
                },
                body: JSON.stringify({
                    model: 'gpt-4',
                    messages: updatedConversationHistory
                })
            });

            const responseData = await response.json();
            if (responseData && responseData.choices && responseData.choices.length > 0) {
                const botResponse = responseData.choices[0].message.content;
                setConversationHistory([
                    ...updatedConversationHistory,
                    { role: 'assistant', content: botResponse }
                ]);
            } else {
                throw new Error('No valid response data.');
            }
        } catch (error) {
            console.error('Error:', error);
            setConversationHistory([
                ...updatedConversationHistory,
                { role: 'assistant', content: 'Bot: Error - Failed to retrieve response.' }
            ]);
        }
    };

    const applySettings = () => {
        document.body.style.backgroundColor = bgColor;
        document.body.style.fontFamily = font;

        if (bgImage) {
            const reader = new FileReader();
            reader.onload = function (e) {
                document.body.style.backgroundImage = `url('${e.target.result}')`;
            };
            reader.readAsDataURL(bgImage);
        } else {
            document.body.style.backgroundImage = 'none';
        }

        setSettingsVisible(false);
    };

    const renderChatLog = () => {
        return conversationHistory.map((message, index) => (
            <div
                key={index}
                className={`message ${message.role === 'user' ? 'user-message' : 'bot-message'}`}
            >
                {message.role === 'user' ? `You: ${message.content}` : `${selectedFriend}: ${message.content}`}
            </div>
        ));
    };

    return (
        <div className="container">
            <div>
                <h2>AI Core: OpenAI API ChatBot</h2>
            </div>

            <button className="friends-button" onClick={() => setChatVisible(!chatVisible)}>
                <i className="fas fa-user-friends"></i>
            </button>

            <div className="friends-container">
                <div className="friends-list" style={{ display: chatVisible ? 'block' : 'none' }}>
                    <h2>Select a Friend</h2>
                    <ul>
                        {friendItems.map((friend, index) => (
                            <li key={index} onClick={() => selectFriend(friend)}>
                                {friend}
                            </li>
                        ))}
                    </ul>
                    <button onClick={() => setSettingsVisible(true)}>+</button>
                </div>
            </div>

            <button className="settings-button" onClick={() => setSettingsVisible(!settingsVisible)}>
                <i className="fas fa-cog"></i>
            </button>

            {settingsVisible && (
                <div className="settings-panel">
                    <label htmlFor="bgColorPicker">Choose background color:</label>
                    <input
                        type="color"
                        id="bgColorPicker"
                        name="bgColorPicker"
                        value={bgColor}
                        onChange={(e) => setBgColor(e.target.value)}
                    />
                    <button className="remove-button" onClick={() => setBgColor('')}>X</button>

                    <label htmlFor="bgImagePicker">Upload background image:</label>
                    <input
                        type="file"
                        id="bgImagePicker"
                        name="bgImagePicker"
                        accept="image/*"
                        onChange={(e) => setBgImage(e.target.files[0])}
                    />
                    <button className="remove-button" onClick={() => setBgImage(null)}>X</button>

                    <label htmlFor="fontPicker">Choose font:</label>
                    <select
                        id="fontPicker"
                        name="fontPicker"
                        value={font}
                        onChange={(e) => setFont(e.target.value)}
                    >
                        <option value="Arial, sans-serif">Arial</option>
                        <option value="'Courier New', Courier, monospace">Courier New</option>
                        <option value="'Times New Roman', Times, serif">Times New Roman</option>
                        <option value="'Comic Sans MS', cursive, sans-serif">Comic Sans MS</option>
                        <option value="Verdana, Geneva, sans-serif">Verdana</option>
                        <option value="Georgia, serif">Georgia</option>
                        <option value="Tahoma, Geneva, sans-serif">Tahoma</option>
                        <option value="'Trebuchet MS', Helvetica, sans-serif">Trebuchet MS</option>
                        <option value="Impact, Charcoal, sans-serif">Impact</option>
                        <option value="'Lucida Console', Monaco, monospace">Lucida Console</option>
                        <option value="'Roboto', sans-serif">Roboto</option>
                        <option value="'Open Sans', sans-serif">Open Sans</option>
                    </select>

                    <button className="small-button" onClick={applySettings}>Apply</button>
                </div>
            )}

            {chatVisible && (
                <div id="chatContainer" className="chat-container">
                    <button className="close-button" onClick={() => setChatVisible(false)}>
                        <i className="fas fa-times"></i>
                    </button>

                    <div id="chatLog" className="chat-log">
                        {renderChatLog()}
                    </div>

                    <div className="message-input">
                        <textarea
                            id="inputText"
                            placeholder="Type your message..."
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    generateResponse(e.target.value);
                                }
                            }}
                        />
                        <button id="submitButton" onClick={() => generateResponse(document.getElementById('inputText').value)}>Send</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MultiPersonalities;
